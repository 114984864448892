<template>
  <div class="card h-100" v-loading="fullScreenLoading">
      <div class="row card-header border-0 pt-6 mb-10">
        <div class="col-md-12">
          <el-form
            @submit.prevent="submitForm()"
            :model="formData"
            ref="formRef"
            class="w-100"
          >
          <div class="row" >
            <div class="col-md-2" v-if="permission.isAddAllowed">
              <label class="fs-6 fw-bold mb-2"> {{ generalConstants.SELECTPRODUCT }}</label>
              <el-form-item prop="productId">
              <el-select
                v-model="formData.productId"
                filterable
                :placeholder="generalConstants.SELECTPRODUCT"
                @change="getProductWeightData(formData.productId)"
                size="large"
              >
              <el-option :label="pdata.product_name" :value="pdata.product_id" :key="pdata"  v-for="pdata in productData"></el-option>
              </el-select>
              </el-form-item>
            </div>
            <div class="col-md-8">
              <label class="fs-6 fw-bold mb-2" v-if="formData.productId != ''">{{ weightConstants.DEFINEWEIGHT }}</label>
              <el-form-item prop="product" v-if="formData.productId != ''">
              <el-select
                v-model="formData.setting_type"
                :placeholder="generalConstants.SELECTSIZE"
                name="product"
                @change="showHideMasterOption(formData.setting_type)"
                size="large"
              >
                <el-option label="Select Option" value="" />
                <el-option label="Size" value="0" />
                <el-option label="Size with Product Option" value="1" />
                <el-option label="Size with Product Option Combination" value="2" />
              </el-select>
            </el-form-item>
            </div>
            <div class="col-md-auto me-0 ms-auto" v-if="permission.isImportAllowed">
            <a
              href="javascript:void(0);"
              class="btn btn-purple"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
              v-if="permission.isImportAllowed || permission.isImportHistoryAllowed"
              ><i class="bi bi-file-earmark-arrow-up fs-4"></i> {{ weightConstants.IMPORTWEIGHTBTN }}
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-purple fw-bold fs-7 w-250px py-4" data-kt-menu="true">
              <div class="menu-item px-3" v-if="permission.isImportAllowed">
                <a @click="$router.push({ name: 'import-product-weight' })" class="menu-link px-3" ><i class="bi bi-file-earmark-arrow-up fs-4"></i>&nbsp; {{ weightConstants.IMPORTWEIGHTBTN }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isImportAllowed">
                <a @click="$router.push({ name: 'import-product-weight-history' })" class="menu-link px-3"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ weightConstants.IMPORT_PRODUCT_WEIGHT_HISTORY }}</a>
              </div>
            </div>
            </div>
          </div>
        </el-form>
        </div>
      </div>
      <div class="row border-0 card-header">
        <el-form
          @submit.prevent="submitWeightForm()"
          :model="formWeightData"
          ref="formWeightRef"
          v-if="showTable"
        >
        <div class="row g-9">
          <div class="col-md-12">
            <table class="table table-row-dashed fs-6 datatable" v-loading="loading">
              <thead>
                <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                  <th>{{ weightConstants.PRODUCTNAME }}</th>
                  <th>{{ weightConstants.SIZE }}</th>
                  <th>{{ generalConstants.SKU }}</th>
                  <th>{{ weightConstants.PRODUCTOPTION }}</th>
                  <th>{{ weightConstants.INDIVIDUALWEIGHT }}</th>
                </tr>
              </thead>
              <tbody class="fw-bold text-gray-600" v-if="formWeightData.keyData.length > 0">
                <tr v-for="(weight, index) in formWeightData.keyData" :key="index" v-bind:id="'row_'+index" align="center">
                  <td><a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'view-product', params: { id: weight.productId } })">{{ weight.product_name }}</a></td>
                  <td>{{ weight.size_label }}</td>
                  <td>{{ weight.modify_sku != '' ? weight.modify_sku : weight.sku }}</td>
                  <td>
                    <div class="fs-7 text-muted" v-if="weight.option_name != ''">
                      <span v-for="(optionVal, optionKey) in weight.option_name" :key="optionKey">
                        <div class="fs-7 text-muted">{{ optionVal+' ' }}<span class="attribute-name">{{ weight.attribute_name[optionKey] }}</span><span v-if="optionKey != Object.keys(weight.option_name).length - 1">, </span></div>
                      </span>
                    </div>
                    <div v-else>
                      {{ '-' }}
                    </div>
                  </td>
                  <td width=300>
                     <el-form-item :prop="'keyData.' + index + '.weight'"
                      :rules="{
                        required: true,
                        validator: checkEmptySpace,
                      }">
                      <el-input size="large" v-model="weight.weight" :placeholder="weightConstants.INDIVIDUALWEIGHT" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' />
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="no-data text-center text-gray-800">
                  <td colspan="5">No Data Found!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mt-5 mb-5 text-right">
          <button v-if="formWeightData.keyData.length > 0" type="button" class=" btn btn-primary me-2" :disabled="loading" @click="submitWeightForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
        </div>
        </el-form>
        <span v-if="showTable == false">
          <h3 class="text-center text-black">Please select Product to set weight</h3>
          <div
        class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
        :style="{
          backgroundImage: 'url(' + '/media/illustrations/dozzy-1/17.png' + ')',
        }"
      ></div>
        </span>
      </div>
    </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { notificationFire } from "@/composable/notification.js";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import { getIllustrationsPath } from "@/core/helpers/assets";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "add-edit-product-weight",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formRef = ref(null);
    const formWeightRef = ref(null);
    const disabled = ref(false);
    const loading = ref(false);
    const fullScreenLoading = ref(false);
    const weightloading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const showTable = ref(false);
    const tableMasterData = ref({});
    const productData = reactive([]);
    const generalConstants = globalConstant.general;
    const weightConstants = globalConstant.weight;
    const isDislayCommonSaveBtn = ref(false);
    const formData = ref({
      productId: "",
      setting_type: "",
      additional_option: "",
    });
    const permission = reactive({
      isAddAllowed: false,
      isImportAllowed: false,
    })
    //check additional option validation
    const checkEmptySpace = (rule, value, callback) => {
      if(!/^[0-9]*\.?[0-9]*$/g.test(value)){
        callback(new Error('Enter only numeric value'))
      }
      else {
        callback()
      }
    }

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitWeightForm(formWeightRef, 'save', true)
      }
    })

    //formweightdata object
    const formWeightData = ref({
      keyData: [
        {
          weight_id:"",
          product_sku_id: "",
          setting_type:"",
          size_id:"",
          option_name: "",
          attribute_name: "",
          product_name:"",
          sku:"",
          modify_sku:"",
          size_label:"",
          master_option_id:"",
          master_attribute_id:"",
          weight: "",
          product_id: "",
        },
      ],
    });

    //Weight defining method change event
    const showHideMasterOption = (type) => {
      submitForm();
    }

    //set additional option in particular product
    const getProductWeightData = (id) => {
      formData.value.setting_type = '';
      submitForm();
    }
    
    //Fetch the all the products
    const getProducts = () => {
      fullScreenLoading.value = true;
      ApiService.query('get-active-products')
        .then(({ data }) => {
          if (data) {
            data.data.forEach((obj) => {
              productData.push({
                product_id: obj.product_id,
                product_name: obj.name,
              });
            });
          }
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'product-weight'})
              }
            }
            fullScreenLoading.value = false;
        });
    }


    //Submit form for get the size with combination data
    const submitForm = async (formEl) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (!valid) return false;
        loading.value = true;
        fullScreenLoading.value = true;
        ApiService.post("product-weight/fetch-product-weight-size", {
            productId: formData.value.productId,
            setting_type: formData.value.setting_type,
        })
        .then(({ data }) => {
          formWeightData.value.keyData = [];
          if(data.data){
            data.data.forEach((obj) => {
              formWeightData.value.keyData.push({
                setting_type: obj.setting_type,
                size_id: obj.size_id,
                option_name: obj.option_name != null ? Object.values(obj.option_name).join(',').split(',') : '',
                attribute_name: obj.attribute_name != null ? Object.values(obj.attribute_name).join(',').split(',') : '',
                productId: obj.product_id,
                product_name: obj.get_product.name,
                sku:obj.sku,
                modify_sku: obj.modify_sku,
                size_label: obj.get_size.size,
                master_option_id: obj.master_option_id,
                master_attribute_id: obj.master_attribute_id,
                weight: obj.weight != null ? obj.weight.weight : '',
                weight_id: obj.weight_id,
                product_sku_id: obj.product_sku_id,
              });
            });
            generateHeaderToolbarButtons();
            disabled.value = false;
          }else{
            disabled.value = true;
          }
          if(!formWeightData.value.keyData.length){
            let isDisplaySaveButton = false;
            generateHeaderToolbarButtons(isDisplaySaveButton);
          }
          showTable.value=true;
          loading.value = false;
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
          fullScreenLoading.value = false;
          disabled.value = false;
        });
      });
    };

    //Submit form for add weight data in database
    const submitWeightForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (loading.value = true);
        fullScreenLoading.value = true;
        ApiService.post("product-weight", {
            productId: formData.value.productId,
            setting_type:formData.value.setting_type,
            weightdata: formWeightData.value.keyData,
        }).then(({ data }) => {
          notificationFire(data.message, "success");
          (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (loading.value = false);
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
          }
          (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (loading.value = false);
          fullScreenLoading.value = false;
        });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(isDisplaySaveButton = true){
      return setCurrentPageActionButtons([{"name" :generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false ,'isDisplay': isDisplaySaveButton}]);
    }

    //Set breadcrumbs
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createProductWeight") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("importProductWeight") || role == "Super Admin")
        permission.isImportAllowed = true;

      getProducts();
    })

    return {
      isDislayCommonSaveBtn,
      formData,
      formWeightData,
      submitForm,
      submitWeightForm,
      disabled,
      formRef,
      formWeightRef,
      loading,
      fullScreenLoading,
      weightloading,
      productData,
      showTable,
      tableMasterData,
      getProducts,
      showHideMasterOption,
      getProductWeightData,
      checkEmptySpace,
      generalConstants,
      weightConstants,
      getIllustrationsPath,
      permission
    };
  },
};
</script>
<style lang="scss">
// For Import Button
  .btn.btn-purple{
    background-color: #8618cbbf;
    border-color: #8618cbbf;
    color:#ffffff;
  }
  .btn.btn-purple i{color:#ffffff;}
  .btn.btn-purple svg path{fill:#ffffff !important;}
  .btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
    background-color: #6911a1bf;
    border-color: #6911a1bf;
  }
  .menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: #f5e7ff96;
    color: #8618cbbf;
  }
  .attribute-name {
    color:#009EF7;
  }
</style>
